
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import { ISampleTypeV3 } from '@/types/resources-version-3'
import DialogTitle from '../DialogTitle.vue'

export default defineComponent({
  name: 'RequisitionFilesDialog',
  props: {
    projectData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      requisitionRows: [] as any[],
    }
  },
  computed: {
    ...mapGetters([
      'sampleTypesAsArrayV3',
    ]),
  },
  methods: {
    setDataOnLoad() {
      this.projectData.requisitionFiles.forEach((file: any, index: number) => {
        const rowData = {
          name: `${ this.mixWB('REQUISITION') } ${ index + 1 }`,
          sampleCount: file.sampleCount,
          analysesTotal: file.analysisCount.total,
          analyses: [] as {id: string, count: number}[],
          href: this.getFileUrl(file),
        }

        this.sampleTypesAsArrayV3.forEach((sampleType: ISampleTypeV3) => {
          const item = file.analysisCount[sampleType.id]
          const count = item ? item.count : 0

          rowData.analyses.push({
            id: sampleType.id,
            count,
          })
        })

        this.requisitionRows.push(rowData)
      })

      // Total row
      const {
        allSamplesCount,
        allAnalysesCount,
        analyses,
      } = this.requisitionRows.reduce((prev, row, index) => {
        if (index === 0) {
          this.sampleTypesAsArrayV3.forEach((sampleType: ISampleTypeV3) => {
            prev.analyses.push({
              id: sampleType.id,
              count: 0,
            })
          })
        }
        prev.allSamplesCount += row.sampleCount
        prev.allAnalysesCount += row.analysesTotal

        row.analyses.forEach((analysis: any, index: number) => {
          prev.analyses[index].count += analysis.count
        })

        return prev
      }, {
        allSamplesCount: 0,
        allAnalysesCount: 0,
        analyses: [],
      })

      this.requisitionRows.push({
        name: this.mixWB('W: I alt'),
        sampleCount: allSamplesCount,
        analysesTotal: allAnalysesCount,
        analyses,
      })
    },
    getFileUrl(file: any) {
      return this.mixGetFileUrl({
        path: file.path,
        filename: file.downloadTitle,
      })
    },
  },
  components: { DialogTitle },
  created() {
    this.setDataOnLoad()
  },
})
